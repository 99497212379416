import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.state';

export const userFeature = createFeatureSelector<UserState>('user');

export const selectUser = createSelector(userFeature, (state) => state?.user);

export const selectOrders = createSelector(
  userFeature,
  (state) => state?.user?.orders,
);

export const selectUpdatePasswordLoading = createSelector(
  userFeature,
  (state) => state?.updatePasswordLoading,
);

export const selectUpdateBillingAddressLoading = createSelector(
  userFeature,
  (state) => state?.updateAddressLoading,
);

export const selectMobilityBudget = createSelector(
  userFeature,
  (state) => state?.user?.mobility_budget,
);
